﻿<script lang="ts">
    import tooltip from 'Lib/Utils/Tooltip';

    export let tooltipText: string | undefined = undefined;
</script>

<button use:tooltip={tooltipText} on:click>
    <slot/>
</button>

<style>
    button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0 calc(max(1rem, (100vw - 1500px) * .5));
        cursor: pointer;
        padding: .6rem;
        transition: all .5s ease;
        background-color: var(--secondary-color);
        color: var(--secondary-text-color);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), 0 0 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        border: none;
    }

    button:hover {
        filter: brightness(125%);
    }
</style>
