<script lang="ts">
    import {getContext} from 'svelte';
    import {applicationContextKey} from 'PublicationDependencies';
    import DependencyContainer from 'Lib/DependencyContainer';
    import translations from 'Assets/i18n';
    import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';
    import Table from 'Pages/PublicationPage/Components/Table.svelte';
    import type ColumnOptions from 'Pages/PublicationPage/Views/ColumnOptions';
    import CustomLinkButtonRow from 'Pages/PublicationPage/Views/CustomLinks/CustomLinkButtonRow.svelte';
    import CustomLinkContext from 'Pages/PublicationPage/Lib/CustomLinkContext';
    import CustomLinkService from 'Pages/PublicationPage/Lib/Services/CustomLinkService';
    import {dateFormatter} from 'Pages/PublicationPage/Lib/Formatters';
    import CustomLinkEditor from 'Pages/PublicationPage/Views/CustomLinks/CustomLinkEditor.svelte';
    import FloatingButton from 'Pages/PublicationPage/Components/FloatingButton.svelte';
    import CustomLinkDeletionConfirmationDialog from 'Pages/PublicationPage/Views/CustomLinks/CustomLinkDeletionConfirmationDialog.svelte';

    const translationContext = translations.pages.publication.customLinksListView;
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const {
        customLinksListObservable,
        itemToEditObservable,
        newLinkObservable,
        listSortingObservable,
        linkToDeleteObservable,
    } = applicationContext.get(CustomLinkContext);
    const customLinkService = applicationContext.get(CustomLinkService);

    function sort(key: ColumnKey) {
        customLinkService.toggleSort(key);
    }

    function presentationConnectedIcon(presentationId?: string) {
        return presentationId ? '' : '<span class="mdi mdi-link-off"/>';
    }

    function descriptionFormatter(description?: string): string {
        return description ?? translationContext.labels.noDescription;
    }

    let columns: Map<ColumnKey, ColumnOptions> = new Map<ColumnKey, ColumnOptions>([
        ['presentationId', {
            key: 'presentationId',
            sort: () => undefined,
            name: '',
            formatter: presentationConnectedIcon,
            html: true,
            width: '32px',
        }],
        ['name', {
            key: 'name',
            sort, name: translationContext.tableHeaders.itemName,
            primary: true,
            details: {key: 'description', formatter: descriptionFormatter}
        }],
        ['createdBy', {
            key: 'ownerName',
            sort,
            name: translationContext.tableHeaders.createdBy
        }],
        ['createdAt', {
            key: 'createdAt',
            sort,
            name: translationContext.tableHeaders.createdAt,
            formatter: dateFormatter
        }],
        ['updatedAt', {
            key: 'updatedAt',
            sort,
            name: translationContext.tableHeaders.updatedAt,
            formatter: dateFormatter
        }],
        ['buttons', {
            key: 'buttons',
            width: '160px'
        }],
    ]);
</script>

<FloatingButton tooltipText={translationContext.tooltips.createNewCustomLink}
                on:click={()=>newLinkObservable.set(true)}>
    <span class="mdi mdi-plus"/>
</FloatingButton>
<Table
        columns={columns}
        items={$customLinksListObservable}
        buttons={CustomLinkButtonRow}
        sortingObservable={listSortingObservable}
        emptyPlaceholder={translationContext.noContent}
/>
{#if $itemToEditObservable || $newLinkObservable}
    <CustomLinkEditor/>
{/if}
{#if $linkToDeleteObservable}
    <CustomLinkDeletionConfirmationDialog bind:customLink={$linkToDeleteObservable}/>
{/if}
<style>
    .mdi {
        font-size: 18px;
    }
</style>
