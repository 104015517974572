<script lang="ts">
    import brandLogo from 'Assets/Icons/ActiveGraf_TextLogo_White.png';

    import { onDestroy, onMount, setContext } from 'svelte';

    import PublicationPage from 'Pages/PublicationPage/PublicationPage.svelte';
    import PublicationListMenubar from 'Menubars/PublicationMenubar.svelte';
    import Notifications from 'Components/Notifications.svelte';

    import { applicationContextKey, registerDependencies } from 'PublicationDependencies';
    import NotificationService from 'Lib/Services/NotificationService';
    import LoginForm from 'Pages/LoginPage/LoginForm.svelte';
    import PresentationPage from 'Pages/PresentationPage/PresentationPage.svelte';
    import UserService from 'Lib/Services/UserService';
    import PresentationService from 'Pages/PresentationPage/Lib/Services/PresentationService';
    import PresentationMenubar from 'Menubars/PresentationMenubar.svelte';
    import { Text, Version } from 'Components/UI';
    import * as Style from 'Components/Style';
    import { GeneralClient } from 'Generated/RestClient.g';
    import translations from 'Assets/i18n';

    const applicationContext = registerDependencies();
    setContext(applicationContextKey, applicationContext);
    onDestroy(() => applicationContext.onDestroy());

    const notificationService = applicationContext.get(NotificationService);
    const userService = applicationContext.get(UserService);
    const presentationService = applicationContext.get(PresentationService);

    const translationContext = translations.global;

    const contentContainer = {
        loginPage: { page: LoginForm, menubar: undefined },
        publicationList: { page: PublicationPage, menubar: PublicationListMenubar },
        presentationPage: { page: PresentationPage, menubar: PresentationMenubar },
    };

    let currentContent: { page: any; menubar: any };

    const generalClient = new GeneralClient();
    const versionPromise = generalClient.getVersion();

    onMount(async () => {
        const isUserLoggedIn = await userService.isUserLoggedInAsync();

        if (presentationService.isPresentationOpen()) {
            currentContent = contentContainer.presentationPage;
            return;
        }

        currentContent = isUserLoggedIn ? contentContainer.publicationList : contentContainer.loginPage;

        userService.onLogin.do(() => {
            currentContent = contentContainer.publicationList;
        });

        userService.onLogout.do(() => {
            currentContent = contentContainer.loginPage;
        });

        // for iPad
        window.scrollTo(0,0);
    });
    // :(
    $: disableOverflow = currentContent === contentContainer.presentationPage;
    $: presentationPage = currentContent === contentContainer.presentationPage;
</script>

<header>
    <div class="logo">
        <a
            href="https://activegraf.com"
            target="_blank">
            <img src={brandLogo} />
        </a>
    </div>
    <nav>
        {#if currentContent}
            <svelte:component this={currentContent.menubar} />
        {/if}
    </nav>
</header>

<main
    class:disableOverflow
    class:presentationPage>
    {#if currentContent}
        <svelte:component this={currentContent.page} />
    {/if}
    <Notifications {notificationService} />
</main>

<div class="version-overlay">
    {#await versionPromise then version}
        {#if version.version}
            <Version version={version.version} />
        {/if}
    {:catch _}
        <Text color={Style.colors.negative}>{translationContext.messages.errorWhileFetchingVersion}</Text>
    {/await}
</div>

<style>
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: var(--primary-color);
        color: var(--primary-text-color);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.7);
        height: 48px;
        width: 100%;
    }

    header > * {
        margin: 16px;
    }

    .logo img {
        max-height: 24px;
    }

    main {
        width: 100%;
        height: 100%;
        margin: 16px;
        overflow: auto;
    }

    main.presentationPage {
        margin: 0;
    }

    .version-overlay {
        position: fixed;
        bottom: 0.5rem;
        right: 0.5rem;
    }

    .disableOverflow {
        overflow: clip;
    }
</style>
