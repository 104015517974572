﻿<script lang="ts">
    import {getContext} from 'svelte';
    import {Column, Dropzone} from 'Components/UI';
    import PasswordChangeDialog from 'Pages/PublicationPage/Components/PasswordChangeDialog.svelte';
    import DeletionConfirmationDialog from 'Pages/PublicationPage/Components/DeletionConfirmationDialog.svelte';
    import {applicationContextKey} from 'PublicationDependencies';
    import DependencyContainer from 'Lib/DependencyContainer';
    import translations from 'Assets/i18n';
    import PublicationListContext from 'Pages/PublicationPage/Lib/PublicationListContext';
    import PublicationListService from 'Pages/PublicationPage/Lib/Services/PublicationListService';
    import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';
    import Table from 'Pages/PublicationPage/Components/Table.svelte';
    import type ColumnOptions from 'Pages/PublicationPage/Views/ColumnOptions';
    import {dateFormatter, fileDetailsFormatter} from 'Pages/PublicationPage/Lib/Formatters';
    import PresentationButtonRow from 'Pages/PublicationPage/Views/PresentationButtonRow.svelte';
    import FloatingButton from 'Pages/PublicationPage/Components/FloatingButton.svelte';

    const translationContext = translations.pages.publication.presentationListView;
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);

    const {scenarioListObservable, listSortingObservable, pendingUploadCountObservable, deletableScenarioObservable} =
        applicationContext.get(PublicationListContext);
    const publicationListService = applicationContext.get(PublicationListService);
    const {scenarioIdForPasswordDialogObservable} = applicationContext.get(PublicationListContext);

    async function onFilesDroppedAsync(files: FileList): Promise<void> {
        await publicationListService.uploadScenariosAsync(files);
    }

    function sort(key: ColumnKey) {
        publicationListService.toggleSort(key);
    }

    let columns: Map<ColumnKey, ColumnOptions> = new Map<ColumnKey, ColumnOptions>([
        ['name', {
            key: 'name',
            sort,
            name: translationContext.header.itemName,
            primary: true,
            details: {key: 'files', formatter: fileDetailsFormatter}
        }],
        ['createdBy', {key: 'ownerName', sort, name: translationContext.header.createdBy}],
        ['createdAt', {key: 'createdAt', sort, name: translationContext.header.createdAt, formatter: dateFormatter}],
        ['updatedAt', {key: 'updatedAt', sort, name: translationContext.header.updatedAt, formatter: dateFormatter}],
        ['buttons', {key: 'buttons', width: '196px'}],
    ]);

    let fileInput: HTMLInputElement;
    let files: FileList;
    $: if (files) {
        publicationListService.uploadScenariosAsync(files);
    }

</script>
<FloatingButton
        tooltipText={translations.pages.publication.actions.upload}
        on:click={()=>fileInput?.click()}
>
    <label class="mdi mdi-upload-outline">
        <input
                accept=".actg"
                multiple
                type="file"
                bind:files
                bind:this={fileInput}
        />
    </label>
</FloatingButton>
<Table
        columns={columns}
        items={$scenarioListObservable}
        buttons={PresentationButtonRow}
        sortingObservable={listSortingObservable}
        pendingCount={$pendingUploadCountObservable}
        emptyPlaceholder={translationContext.noContent}
/>
<PasswordChangeDialog bind:scenarioId={$scenarioIdForPasswordDialogObservable}/>
<DeletionConfirmationDialog bind:scenario={$deletableScenarioObservable}/>
<Dropzone onFilesDropped={onFilesDroppedAsync}>
    <Column
            horizontalAlign="center"
            verticalAlign="center">
        <span class="mdi mdi-upload-outline"/>
        {translations.pages.publication.dragAndDropDescription}
    </Column>
</Dropzone>

<style>
    .mdi-upload-outline {
        font-size: 18px;
        pointer-events: none;
    }

    input {
        display: none;
    }
</style>
