<script lang="ts">
    import Text from 'Components/Text.svelte';
    import type ILabelStyle from 'Components/LabelStyle';
    import Column from 'Components/Column.svelte';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let id: Nullable<string> = undefined;
    export let value: Nullable<string> = undefined;
    export let label: Nullable<string> = undefined;
    export let placeholder: Nullable<string> = undefined;
    export let isDisabled: boolean = false;
    export let minLength: Nullable<number> = undefined;
    export let isRequired: boolean = false;
    export let pattern: Nullable<string> = undefined;

    export let errorText: Nullable<string> = undefined;
    export let shouldClearError: boolean = true;

    export let labelStyle: Nullable<ILabelStyle> = undefined;
    export let isHorizontallyExtended: boolean = false;
    export let inputElement: Nullable<HTMLElement> = undefined;

    let hasError: boolean;
    $: hasError = errorText != null;
</script>

<label
    class:isDisabled
    class:isHorizontallyExtended>
    <Column gap="8px">
        {#if label}
            <Text {...labelStyle}>{label}</Text>
        {/if}
        <input
            bind:this={inputElement}
            {id}
            class:hasError
            disabled={isDisabled}
            minlength={minLength}
            {pattern}
            placeholder={errorText ?? placeholder}
            required={isRequired}
            type="text"
            bind:value
            on:blur
            on:keyup
            on:focus
            on:keydown
            on:input
        />
    </Column>
</label>

<style>
    input {
        width: 100%;
        border: rgb(232, 232, 232) solid 2px;
        padding: 6px;
        margin: 0;
        height: 35px;
    }

    input:disabled {
        background-color: rgba(0, 0, 0, 0.05);
        cursor: not-allowed;
    }

    input.hasError {
        border-color: red;
        color: red;
        background-color: rgb(255, 238, 238);
    }

    input:invalid {
        border: 2px solid red;
    }

    * :global(.error > ::placeholder) {
        color: rgba(255, 0, 0, 0.75);
    }

    .isHorizontallyExtended {
        flex-grow: 1;
    }
</style>
